<template>
    <div>
      <div class="register-property-layout">
      <!-- FROMS -->
      <div class="forms-container container2">
        <ul id="progressbar">
          <!-- <li class="active" :class="[isForm1Valid && formStep == 1 ? 'active' : '']"> -->
          <li class="" :class="[isForm1Valid  ? 'active' : '']">
            {{ isMobile ? 'Info.' : "Landlord's Info" }}
          </li>
          <li :class="[isForm2Valid  ? 'active' : '']">
            {{ isMobile ? "Landlord's Property" : "Landlord's Property" }}
          </li>
          <!-- <li :class="[isForm3Valid  ? 'active' : '']">
            {{ isMobile ? 'Media' : 'Images & media' }}
          </li> -->
          <li :class="[formStep == 3 ? 'active' : '']">
            {{ isMobile ? 'Uploads' : 'Uploads' }}
          </li>
        </ul>
        <!-- FORM 1 -->
        <div class="" v-if="formStep == 1">
          <p class="input-error" v-show="this.errorMessage.form">
            {{ this.errorMessage.form }}***
          </p>
          <div class="container__head">
            <h3>General Information</h3>
            <p>
              Complete the form with the landlord’s personal details
            </p>
          </div>

          <div class="container">
            <div class="form">
              <div class="input-group">
                <BaseInput
                  placeholder="Michael"
                  label="First Name"
                  :value="firstName"
                  @input="firstName = $event"
                  :errorMessage="errorMessage.firstName"
                  @blur="validateFirstName"
                />
                <BaseInput
                  label="Last Name"
                  placeholder="Ndubuisi"
                  :value="lastName"
                  @input="lastName = $event"
                  :errorMessage="errorMessage.lastName"
                  @blur="validateLastName"
                />
              </div>
              <div class="input-group">
                <BaseInput
                  type="email"
                  placeholder="example@gmail.com"
                  label="Email Address"
                  :value="email"
                  @input="email = $event"
                  :errorMessage="errorMessage.email"
                  @blur="validateEmail"
                />

                <BaseInput
                  label="Phone number"
                  placeholder="+2348 1437 434"
                  :value="phone"
                  type="number"
                  @input="phone = $event"
                  :errorMessage="errorMessage.phone"
                  @blur="validatePhone"
                />

              </div>

              <div class="input-group">
                <div class="upload-btn-id">
                  <p for="" class="">Select landlord Id</p>
                  <button class="btn" @click="selectLandlordId()">Upload Landlord's ID.</button>
                  <!-- <BaseButtton class="btn" @click="selectLandlordId()">Upload Landlord's ID.</BaseButtton> -->
                  <input type="file" ref="landlordIdRef" @change="handleLandlordId($event)" class="d-none" >
                </div> 
                <BaseInput
                  label="landlord's Id file"
                  placeholder="No file selected"
                  :value="landlordIdFile.name"
                  disabled
                />
              </div>

              <BaseInput
                type="textarea"
                label="Landlord address"
                placeholder="Enter full address"
                :value="address"
                @input="address = $event"
                :errorMessage="errorMessage.address"
                @blur="validateLandlordAddress"
              />

              <InputSelect
                label="Does He/She wants free legal service?"
                @select-option="freeLegalService = $event"
                @blur="validatelegalService"
                :value="freeLegalService"
              >
                <option
                  selected
                  disabled
                  value=""
                  >Select</option
                >
                <option
                  key="YES"
                  value="YES"
                  >YES</option
                >
                <option
                  key="NO"
                  value="NO"
                  >NO</option
                >
              </InputSelect>

              <div v-if="freeLegalService === 'YES'">
                <div class="form-group form-check">
                  <input true-value="YES" false-value="NO" v-model="property_owner" type="checkbox" class="form-check-input" id="exampleCheck1">
                  <label class="form-check-label" for="exampleCheck1">Is He/She the onwer of the property?</label>
                </div>
                <div class="form-group form-check">
                  <input true-value="YES" false-value="NO" v-model="in_court" type="checkbox" class="form-check-input" id="exampleCheck2">
                  <label class="form-check-label" for="exampleCheck2">Is He/She in court already?</label>
                </div>
                
                <textarea
                  v-model="dispute_description"
                  rows="3"
                  type="text"
                  placeholder="Brief description of tenant dispute"
                  label=""
                  class="form-control"
                ></textarea>

                <p class="input-error" v-show="this.errorMessage.legal">
                  {{ this.errorMessage.legal }}
                </p>
              </div>
            </div>
            
            <!-- <div class="btn-container  flex-end">
              <BaseButtton @click="validateForm1">Next</BaseButtton>
            </div> -->

            <div class="btn-container"  style="padding: 1rem;">
              <BaseButtton type="text" @click="goBack()"
                >Cancel</BaseButtton
              >
              <BaseButtton @click="addLandlordStandAlone()" :disabled="loadingL">
                {{ loadingL ? "Processing..." : "Submit" }}
              </BaseButtton>
            </div>
          </div>
        </div>
       
        <!-- FORM 2 -->
        <div class="" v-if="formStep == 2 && skip === false">
          <p class="input-error" v-show="this.errorMessage.form">
            {{ this.errorMessage.form }}***
          </p>
          <div class="container__head">
            <h3>Add property</h3>
            <p class="mb-3" v-if="formStep == 1">
              Please provide us with the details about the property in question.
            </p>
            <p  v-if="formStep == 2" class="mb-3">Add properties owned by this landlord, or skip this step and return to it later.</p>
          </div>

          <div class="form">
            <div class="email-container input-full">
              <BaseInput
                type="text"
                placeholder="Eg: newly built 3 bedroom flat"
                label="Title"
                :value="propertyTitle"
                @input="propertyTitle = $event"
                :errorMessage="errorMessage.title"
                @blur="validateTitleInput"
              />
            </div>

            
            <div class="input-group">
              <InputSelect
                label="Property Type"
                @select-option="propertyType = $event"
                @blur="validatePropertyType"
                :value="propertyType"
                :errorMessage="errorMessage.propertyType"
                :loading="loadingPropertyType"
              >
                <option
                  v-for="propertyType in propertyTypes"
                  :key="propertyType.id"
                  :value="propertyType.type"
                  >{{ propertyType.type }}</option
                >
              </InputSelect>
              <InputSelect v-if="propertyType != 'Residential' && propertyType != ''"
                label="Property subtype"
                @select-option="propertySubType = $event"
                @blur="validatePropertySubType"
                :value="propertySubType"
                :errorMessage="errorMessage.propertySubType"
                :loading="loadingPropertySubType"
              >
                <option
                  v-for="subType in propertySubTypes"
                  :key="subType.id"
                  :value="subType.subtype"
                  >{{ subType.subtype }}</option
                >
              </InputSelect>
              
            </div>

            <div class="input-group">
              <InputSelect
                label="Business Type"
                @select-option="typeofbusiness = $event"
                @blur="validateBusinessType"
                :value="typeofbusiness"
                :errorMessage="errorMessage.typeofbusiness"
              >
                <option selected disabled>Select</option>
                <option  v-for="item in businesstypenumber"
                  :key="item.id"
                  :value="item.id">{{ item.businesstype }}</option>
              </InputSelect>
            </div>

            <div class="input-group">
              <BaseInput v-if="propertyType != 'Residential' && propertyType != ''"
                type="text"
                placeholder="Property price"
                label="Price"
                :value="theRentValueAmount"
                @input="theRentValueAmount = $event"
                :errorMessage="errorMessage.genericPrice"
                @blur="validateGenericPriceInput"
                />
            </div>


            <div class="input-group">
              <InputSelect
                label="State"
                @select-option="location = $event"
                @blur="validateLocation"
                :value="location"
                :errorMessage="errorMessage.location"
                :loading="loadingLocation"
              >
                <option
                  v-for="state in sortedStates"
                  :key="state.id"
                  :value="state.name"
                  >{{ state.name }}</option
                >
              </InputSelect>

              <InputSelect
                label="City"
                @select-option="city = $event"
                @blur="validateCity"
                :value="city"
                :errorMessage="errorMessage.city"
                :loading="loadingCity"
              >
                <option
                  v-for="city in sortedCity"
                  :key="city.id"
                  :value="city.name"
                  >{{ city.name }}</option
                >
              </InputSelect>
              
            </div>

            <div v-if="propertyType != 'Residential' && propertyType != 'Land' && propertyType != ''">
              <div class="email-container input-group">
                <InputSelect
                label="Bedrooms"
                @select-option="bedrooms = $event"
                :value="bedrooms"
                :errorMessage="errorMessage.bedrooms"
                >
                  <option  value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </InputSelect>
                <InputSelect
                  label="Bathrooms"
                  @select-option="bathrooms = $event"
                  :value="bathrooms"
                  :errorMessage="errorMessage.bathrooms"
                >
                  <option  value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </InputSelect>

              </div>
            </div>

            <div>
              <div class="email-container input-group">
                <InputSelect
                  v-if="propertyType != 'Residential' && propertyType != 'Land' && propertyType != ''"
                  label="Toilets"
                  @select-option="toilets = $event"
                  :value="toilets"
                  :errorMessage="errorMessage.toilets"
                  >
                    <option  value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </InputSelect>
                  <InputSelect
                    v-if="propertyType != 'Residential' && propertyType != ''"
                    label="Status"
                    @select-option="status = $event"
                    @blur="validateStatus"
                    :value="status"
                    :errorMessage="errorMessage.status"
                    :loading="loadingStatus"
                    >
                  <option
                      v-for="status in propertyStatuses"
                      :key="status"
                      :value="status"
                      >{{ status }}</option
                  >
                  </InputSelect>
              </div>
            </div>
            <!-- <div class="input-group">
              <InputSelect
                label="Status"
                @select-option="propertyStatus = $event"
                @blur="validateStatus"
                :value="propertyStatus"
                :errorMessage="errorMessage.status"
                :loading="loadingStatus"
              >
                <option
                  v-for="status in propertyStatuses"
                  :key="status"
                  :value="status"
                  >{{ status }}</option
                >
              </InputSelect>
            </div> -->
            <div class="email-container input-full">
              <BaseInput
                type="text"
                placeholder="Enter property accurate address"
                label="Property address"
                :value="propertyAddress"
                @input="propertyAddress = $event"
                :errorMessage="errorMessage.propertyAddress"
                @blur="validatePropertyAddress"
              />
            </div>
            <div class="input-group">
              <BaseInput
              type="textarea"
              placeholder="Provide landmarks or directions, a detailed property description, and any additional remarks."
              label="Property description"
              :value="description"
              @input="description = $event"
              :errorMessage="errorMessage.description"
              @blur="validateDescription"
            />
            </div>

            <div v-if="propertyType == 'Residential' && propertyType != ''">
              <div class="addNewUnit">
                <div class="unit-text-button">
                  <h5>Units</h5>
                  <button class="btn" @click="addForm()"><span>+</span> Add unit</button>
                </div>
                <h6>Add the individual units within this property. Each unit can be tracked and updated separately.</h6>
              </div>
          
              <div v-for="(form, index) in forms" :key="index">
                <div class="unit-count-holder">
                  <h3>Unit {{ index + 1 }}</h3>
                  <div>
                    <!-- <small>Save</small> -->
                    <img style="cursor: pointer;" v-if="forms.length > 1 && index != 0" @click="removeForm(index)" src="../assets/icons/trash.svg" alt="delete">
                  </div>
                </div>
    
                <form @submit.prevent="submitForm(index)">
                  <div class="input-group">
                    <div class="row">
                      <div class="col-md-4">
                        <label for="name">Unit name:</label>
                        <!-- <input class="form-control dynamic-input" id="name" type="text" v-model="form.name" placeholder="Eg. Main" /> -->
                        <select class="form-control dynamic-input" id="bathroom" v-model="form.unit_name">
                          <option  value="" disabled>Select</option>
                          <option
                            v-for="(category, index) in propertyCategory"
                            :key="index"
                            :value="category"
                            >{{ category }}</option
                          >
                    
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="price">Unit Amount:</label>
                        <input class="form-control dynamic-input" id="price" type="text" v-model="form.price" @input="formatPrice(index)" placeholder="Enter unit amount" />  
                      </div>
                      <div class="col-md-4">
                        <label for="bedrooms">Number of bedrooms:</label>
                        <select class="form-control dynamic-input" id="bedroom" v-model="form.bedrooms">
                          <option value="" selected disabled>Select</option>
                          <option  value="1">1</option>
                          <option value="2">2</option>
                          <option class="3">3</option>
                          <option class="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="bedrooms">Number of bathrooms:</label>
                        <select class="form-control dynamic-input" id="bathroom" v-model="form.bathrooms">
                          <option value="" selected disabled>Select</option>
                          <option  value="1">1</option>
                          <option value="2">2</option>
                          <option class="3">3</option>
                          <option class="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="bedrooms">Number of toilets:</label>
                        <select class="form-control dynamic-input" id="bathroom" v-model="form.toilets">
                          <option value="" selected disabled>Select</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option class="3">3</option>
                          <option class="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="status">Status:</label>
                        <select class="form-control dynamic-input" name="status" id="status" v-model="form.status">
                          <option value="" disabled>Select status</option>
                          <option
                            v-for="status in propertyStatuses"
                            :key="status"
                            :value="status"
                            >{{ status }}</option
                          >
                        </select>
                        
                      </div>
                      
                    </div>
                  </div>
                  
                </form>
              </div>
              <div class="col-md-4 form-group pl-0">
                <label for="bedrooms">Number of units to replicate (optional): <span>{{ forms.length }}</span></label>
                <input class="form-control dynamic-input" id="unitNumber" type="number" v-model="duplicateFormNumber" placeholder="Enter number of times..." />
              </div>
            </div>
            <div class="btn-container">
              <BaseButtton type="text" @click="formStep--"
                >Previous</BaseButtton
              >
              <BaseButtton @click="validateForm2">Next</BaseButtton>
            </div>
          </div>

         
        </div>
        

        <div class="" v-if="formStep == 2 && skip === true">
            <div class="no-prop-add" @click="skip = false">
              <h2>+</h2>
              <h6>Add Property</h6>
            </div>
            <div class="btn-container"  style="padding: 1rem;" @click="goBack()">
              <BaseButtton type="text"
                >Cancel</BaseButtton
              >
              <div>
                <BaseButtton type="border" class="mr-3" @click=" goBack()">Skip</BaseButtton>
                <!-- <BaseButtton disabled="disabled" @click="validateForm1; nextTick()">
                  <span>Proceed</span>
                </BaseButtton> -->
              </div>
            </div>
        </div>
         <!-- FORM 2 -->

        <!-- FORM 3 -->
        <div class="" v-if="formStep == 3">
          <p class="input-error" v-show="this.errorMessage.form">
            {{ this.errorMessage.form }}***
          </p>
          <div class="container__head">
            <h3>Uploads</h3>
            <p>
              Upload property documents and images
            </p>
          </div>

          <div class="form">
              <div class="upload-holder">
                <div class="holding-upload">
                  <div class="">
                    <h4>Proof of ownership (PDF only)</h4>
                    <h4 v-if="uploadingEvidence" class="uploading-doc">Uploading documents....</h4>
                    <p v-if="!evidenceFile"><img src="../assets/icons/file.svg" alt="file"> No file seletected</p>
                    <p v-if="evidenceFile && !uploadingEvidence">
                      <img src="../assets/icons/file.svg" alt="file">
                      {{ evidenceServerFileName }}
                    </p>
                      <!-- <h6>Pending verification</h6> -->
                  </div>
                  <button class="btn" @click="selectFileEvidenceOfProperty" :disabled="uploadingEvidence">
                    {{ uploadingEvidence ? 'Uploading...' : 'Upload' }}
                  </button>
                  <input @blur="validatePropertyDoc" type="file" ref="propertyEvidence" @change="handleFileUploadEvidence($event)" class="d-none" >
                </div>
              </div>

              <!-- <div class="upload-holder">
                <div class="holding-upload">
                  <div class="">
                    <h4>Proof of address</h4>
                      <p>
                        <img src="../assets/icons/file.svg" alt="file">
                        proof of address.docx
                      </p>
                  </div>
                  <button class="btn">Upload</button>
                </div>
              </div> -->

              <div class="upload-holder">
                <div class="holding-upload">
                  <div class="">
                    <h4>Property images ({{ uploadedPhotos.length }})</h4>
                    <h4 class="uploading-doc" v-if="uploadingPhoto">Uploading photo....</h4>
                    <p v-if="uploadedPhotos.length == 0 && !uploadingPhoto"><img src="../assets/icons/file.svg" alt="file"> No image seletected</p>
                    <p v-for="(item, index) in uploadedPhotos" :key="index">
                      <img src="../assets/icons/file.svg" alt="file">
                      {{item}} <span class="d-none" @click.stop="removeUploadedImages(index)">X</span>
                    </p>
                    <!-- <p>
                      <img src="../assets/icons/file.svg" alt="file">
                      dinning room.png
                    </p> -->
                    <!-- </div> -->
                    <!-- <h6>Pending verification</h6> -->
                  </div>
                  <button class="btn" @click="selectPropertyImage" :disabled="uploadingPhoto">
                    {{ uploadingPhoto ? 'Uploading...' : 'Upload' }}
                  </button>
                  <input @blur="validatePropertyImage" type="file" ref="propertyImage" @change="handleFileUploadImages($event)" class="d-none" >
                </div>
              </div>
            </div>
            

          <!-- <div class="d-none">
            <div class="review-top-text">
              <h5>General Info.</h5>
              <h6>Modify</h6>
            </div>
            <div class="email-container info-holder">
              <div class="row general-info-content">
                <div class="col-md-6">
                  <h6>Name:</h6>
                  <h5>Afinwaketung Oluremidapo</h5>
                </div>
                <div class="col-md-6">
                  <h6>Email address:</h6>
                  <h5>Afinwaketung.oluremi@gmail.com</h5>
                </div>
                <div class="col-md-6">
                  <h6>Phone number:</h6>
                  <h5>08034567389</h5>
                </div>
                <div class="col-md-6">
                  <h6>Landlord Address:</h6>
                  <h5>43 Oladipo close Lagos, Nigeria</h5>
                </div>
              </div>
            </div>

          </div>

          <div class="d-none">
            <div class="review-top-text">
              <h5>Properties</h5>
              <h6>Modify</h6>
            </div>
            <div class="email-container info-holder">
              <div class="general-info-content mb-0">
                <div class="prop-reviw-holder mb-0">
                  <div>
                    <h5>Three bedroom flat</h5>
                    <h6>25 Lokoja road Abaji Fct, Nigeria</h6>
                    <h6>3 Units</h6>
                  </div>
                  <img src="../assets/icons/menu-icon.svg" alt="menu">
                </div>        
              </div>
            </div>
          </div> -->
          <div class="btn-container">
            <BaseButtton type="text" @click="formStep--">Cancel</BaseButtton>
            <BaseButtton @click="submitFormAddProperty()" :disabled="submittingProp || uploadingPhoto || uploadingEvidence">
              {{ submittingProp ? "Processing..." : "Submit" }}
            </BaseButtton>
          </div>
        </div>
      </div>
     
    </div>
    </div>
</template>

<script>
// import CF from "../helpers/currencyFormatter";
// import Loader from './Loader';
import properties from "../services/properties";
import BaseButtton from "../components/UI/BaseButtton.vue";
import BaseInput from "../components/form/BaseInput.vue";
import InputSelect from "../components/form/InputSelect.vue";
import { nextTick } from 'vue';

// import $ from "jquery";
export default {
  name: "AddLandlordProperty",
  components: {
    BaseInput,
    InputSelect,
    BaseButtton,
    // Loader,
  },
  data() {
    return {
        loading: false,
        loadingL: false,
        loadingPropertyType: false,
        loadingPropertySubType: false,
        loadingLocation: false,
        loadingCity: false,
        loadingStatus: false,
        submittingProp: false,
        uploadingPhoto: false,
        uploadingEvidence: false,
        freeLegalService: 'NO',
        duplicateFormNumber: '',
        formStep: 1,
        isMobile: false,
        skip: true,
        propertyCategory: [],
        propertyStatuses: [],
        // title: '',
        propertyTitle: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        propertyAddress: "",
        address: "",
        amount: "",
        amount2: "",
        propertyTypes: [],
        propertySubTypes: [],
        propertySubType: "",
        propertyType: "",
        propertyValue: "",
        noOfBedroom: "",
        noOfBathroom: "",
        noOfToilets: "",
        bedrooms: "",
        bathrooms: "",
        toilets: "",
        status: "",
        description: "",
        // leaseOrRent: "",
        isFurnished: "no",
        propertyStatus: "",
        businesstypenumber: "",
        propertyAware: "",
        squareFootage: "",
        states: [],
        isEmailValid: false,
        location: "",
        city: "",
        cities: [],
        stateIdd: "",
        propertyAwarenessTypes: [],
        bedroomOptions: [],
        denomination: "naira",
        // businesstype: 1,
        typeofbusiness: "",
        subscription: "",
        subtypeId: "",
        facilities: "",
        evidenceServerFileName: "",
        evidenceFile: "",
        propertyImageFile: "",
        landlordIdFile: "",
        uploadingLandlordId: false,
        genericPrice: "",
        dispute_description: "",
        property_owner: "NO",
        in_court: "NO",
        forms: [
          {
            unit_name: '',
            price: '',
            bedrooms: '',
            bathrooms: '',
            toilets: '',
            status: '',
          },
        ],
        errorMessage: {
          title: "",
          propertyTitle: "",
          noOfBedroom: "",
          noOfBathroom: "",
          noOfToilets: "",
          bedrooms: "",
          bathrooms: "",
          toilets: "",
          status: "",
          propertyType: "",
          propertySubType: "",
          squareFootage: "",
          description: "",
          // leaseOrRent: "",
          // typeofbusiness: "",
          propertyValue: "",
          form: "",
          firstName: "",
          propertyAddress: "",
          lastName: "",
          genericPrice: "",
          email: "",
          phone: "",
          amount: "",
          amount2: "",
          address: "",
          age: "",
          gender: "",
          location: "",
          city: "",
          // evidenceServerFileName: "",
          propertyImageFile: "",
          uploadedPhotos: [],
          evidenceFile: "",
          legal: "",
        },
        uploadedPhotos: [],
        propertyFirstImage: ""
    };
  },
  created() {
    const user = this.$store.getters.getUser;
    this.userID = user.id;

  },
  mounted() {
    this.getPropertyStatuses();
    this.getPropertyTypes();
    this.getStates();
    this.getBusinessTypes();
    this.getPropCategory();
    // this.getPropertyAwarenessTypes();
  },
  beforeDestroy() {
  },
  methods: {
    nextTick() {
      // console.log("disabled")
    },
    async goBack() {
      await nextTick(); // Wait for Vue's DOM updates before navigation
      // router.push('/user/landlords');
      this.$router.push("/user/landlords");
    },
    formatPrice(index) {
      // Remove existing commas
      const unformatedPrice = this.forms[index].price.replace(/,/g, "");
      // Add commas back in
      this.forms[index].price = new Intl.NumberFormat().format(unformatedPrice);
    },
    addForm() {   
      const lastForm = this.forms[this.forms.length - 1]; // Get the last form
      // Validate if all fields of the last form are filled
      if (!lastForm.unit_name || !lastForm.price || !lastForm.bedrooms || !lastForm.bathrooms || !lastForm.toilets || !lastForm.status) {
        this.$toast.error("Please fill in all fields in the unit form before adding a new form.");
        return;
      }
      // Add a new form if validation passes
      this.forms.push({ unit_name: '', price: '', bedrooms: '',  bathrooms: '', toilets: '', status: ''});
      // console.log("Formssss", this.forms);  // Check if a new form is added
    },
    removeForm(index) {
      this.forms.splice(index, 1); // Remove 1 element at the given index
    },
    duplicateFormData(newData, oldData) {
      const lastForm = this.forms[this.forms.length - 1]; 
      if (!lastForm.unit_name || !lastForm.price || !lastForm.bedrooms|| !lastForm.bathrooms || !lastForm.toilets || !lastForm.status) {
        this.$toast.error("Please fill in all fields in the unit form before adding a new form.");
        return;
      }

      if (this.propertyType == 'Residential' ) {
        if (!lastForm.unit_name || !lastForm.price || !lastForm.bedrooms || !lastForm.bathrooms || !lastForm.toilets || !lastForm.status )
         return this.$toast.error("Fill all required forms");
      }

      if (this.propertyType != 'Residential') {
        this.forms = [];
        return this.$toast.error("Fill all required fileds");
      }

      if (newData < 1) return;
      if (newData > oldData) {
        // Add new objects to the array
        const additionalObjects = Array.from(
          { length: newData - oldData },
          () => ({ 
            unit_name: this.forms[0].unit_name,
            price: this.forms[0].price,
            bedrooms: this.forms[0].bedrooms,
            bathrooms: this.forms[0].bathrooms,
            toilets: this.forms[0].toilets,
            status: this.forms[0].status,
          })
        );
        this.forms.push(...additionalObjects);
      } else if (newData < oldData) {
        // Remove excess objects from the array
        this.forms.splice(newData);
      }

      // const duplicatedArray = this.forms.flatMap(item => Array(Number(`${this.duplicateFormNumber}`)).fill({ ...item }));
      // this.forms = duplicatedArray;
      // console.log("Duplicated form", duplicatedArray, this.forms);
    }, 
    selectPropertyImage() {
      this.$refs.propertyImage.click();
    },
    selectFileEvidenceOfProperty() {
      this.$refs.propertyEvidence.click();
    },
    handleFileUploadEvidence(event) {
      this.uploadingEvidence = true;
      this.evidenceFile = event.target.files[0];
      // const file = event.target.files[0];
      this.uploadingEvidence = true;
      this.evidenceFileName = this.evidenceFile.name;

      if (!this.evidenceFile) {
        this.uploadingEvidence = false;
        // this.$toast.success("success");
        return this.$toast.error("select a file");
      }
      if (
          this.evidenceFile.type === "application/pdf"
          // this.evidenceFile.type === "image/jpeg" ||
          // this.evidenceFile.type === "image/jpg" ||
          // this.evidenceFile.type === "image/png" ||
          // this.evidenceFile.type === "image/gif" ||
          // this.evidenceFile.type === "application/pdf" ||
          // this.evidenceFile.type === "svg+xml"
      ) {
        this.uploadingEvidence = true;
      } else {
        this.uploadingEvidence = false;
        this.evidenceFile = "";
        return this.$toast.error("select a PDF file");
      }
      
      // Handle the uploaded file here
      const sizeInMb = this.evidenceFile.size / (1024 * 1024).toFixed(3);
      if (sizeInMb > 5) {
        this.uploadingEvidence = false;
        return this.$toast.error("File size more than 5MB");
      }
      let formData = new FormData();
      formData.append("file", this.evidenceFile);
      formData.append("documentType", this.evidenceFile.name);
      properties.uploadPropertyEvidenceOfOwener(formData)
      .then((res) => {
        this.uploadingEvidence = false;
        // console.log("evidence File", res);
        if (res.success) {
            this.evidenceServerFileName = res.filename
          this.$toast.success("SUCCESS");
        } else {
          // this.$toast.error("ERROR");
          this.$toast.error(res.error);
        }
      })
      .catch((err) => {
        console.log(err);
        this.uploadingEvidence = false;
      });

    },
    handleFileUploadImages(event) {
      this.uploadingPhoto = true;
      this.propertyImageFile = event.target.files[0];
      const file = event.target.files[0];
      // Handle the uploaded file here
      this.propertyImageFile = event.target.files[0];
      // const file = event.target.files[0];
      this.uploadingPhoto = true;
      if (this.uploadedPhotos.length == 0 && this.propertyImageFile) {
        this.propertyFirstImage = URL.createObjectURL(file);
      }
     

      if (!this.propertyImageFile) {
        this.uploadingPhoto = false;
        // this.$toast.success("success");
        return this.$toast.error("select a file");
      }
      if (
          // this.evidenceFile.type != "application/pdf"
          this.propertyImageFile.type === "image/jpeg" ||
          this.propertyImageFile.type === "image/jpg" ||
          this.propertyImageFile.type === "image/png" ||
          this.propertyImageFile.type === "image/gif" ||
          // this.propertyImageFile.type === "application/pdf" ||
          this.propertyImageFile.type === "svg+xml"
      ) {
        this.uploadingPhoto = true;
      } else {
        this.uploadingPhoto = false;
        this.propertyImageFile = "";
        this.uploadingPhoto = false;
        return this.$toast.error("select a IMAGE file");
      }
      
      // Handle the uploaded file here
      const sizeInMb = this.propertyImageFile.size / (1024 * 1024).toFixed(3);
      if (sizeInMb > 5) {
        this.uploadingPhoto = false;
        return this.$toast.error("File size more than 5MB");
      }
      let formData = new FormData();
      formData.append("file", this.propertyImageFile);
      formData.append("documentType", this.propertyImageFile.name);
      properties.uploadPropertyimages(formData)
      .then((res) => {
        this.uploadingPhoto = false;
        if (res.success) {
          JSON.stringify(this.uploadedPhotos.push(res.filename));
          window.localStorage.setItem("__uploadedImages", JSON.stringify(this.uploadedPhotos))
          // console.log("Uploaded file", this.uploadedPhotos);
          this.$toast.success("SUCCESS");
        } else {
          this.$toast.error("ERROR");
        }
      })
      .catch((err) => {
        console.log(err);
        this.uploadingPhoto = false;
      });

    },
    selectLandlordId() {
      this.$refs.landlordIdRef.click();
    },
    handleLandlordId(event) {
        this.landlordIdFile = event.target.files[0];
        // const file = event.target.files[0];
        // // Handle the uploaded file here
        // this.landlordIdFile = event.target.files[0];
        // const file = event.target.files[0];
        // this.uploadingLandlordId = true;
        // if (this.uploadedPhotos.length == 0) {
        //     this.propertyFirstImage = URL.createObjectURL(file);
        // }
        

        // if (!this.landlordIdFile) {
        //     this.uploadingPhoto = false;
        //     // this.$toast.success("success");
        //     return this.$toast.error("select a file");
        // }
        if (
            // this.evidenceFile.type != "application/pdf"
            this.landlordIdFile.type === "image/jpeg" ||
            this.landlordIdFile.type === "image/jpg" ||
            this.landlordIdFile.type === "image/png" ||
            this.landlordIdFile.type === "image/gif" ||
            // this.propertyImageFile.type === "application/pdf" ||
            this.landlordIdFile.type === "svg+xml"
        ) {
            this.uploadingLandlordId = true;
        } else {
            this.uploadingLandlordId = false;
            this.landlordIdFile = "";
            return this.$toast.error("select an image file type");
        }
        
        // Handle the uploaded file here
        const sizeInMb = this.landlordIdFile.size / (1024 * 1024).toFixed(3);
        if (sizeInMb > 5) {
            this.uploadingLandlordId = false;
            return this.$toast.error("File size more than 5MB");
        }
        let formData = new FormData();
        formData.append("file", this.landlordIdFile);
    },
    submitFormAddProperty() {
      // Handle form submission for the form at the given index
      // console.log('Form Data:', this.forms[index]);
      // alert(`Form ${index + 1} Submitted!`);

      this.submittingProp = true;
      this.genericPrice = this.theRentValueAmount.replace(/,/g, "");
      let updatedArray = this.forms.map((item) => ({
          ...item,
          price: item.price.replace(/,/g, ""),
        }));
      let isSubType = ''; 
      if (this.propertyType != 'Residential') {
        isSubType = this.propertySubTypeId;
        updatedArray = [];
      }
      let url;
      if (this.propertyType == 'Residential') {
        url = 'smart-associate-multiple-property-addition'
      }
      if ((this.propertyType == 'Commercial') || (this.propertyType == 'Industrial')) {
        url = 'smart_add_commercial_and_industrial'
      }

      if (this.propertyType == 'Land') {
        url = 'smart_add_land_for_property'
      }
      if (this.isForm2Valid) {
      // if (this.isForm2Valid && this.isForm3Valid) {
        const body = {
          userId: this.userID,
          units_objects: updatedArray,
          landlord_id: localStorage.getItem('new_land_lordid'),
          // facilities: this.facilities,
          subtype: isSubType,
          type: this.propertyTypeId,
          cityid: this.cityId,
          stateid: this.stateId,
          address: this.propertyAddress,
          businesstype: Number(this.typeofbusiness),
          description: this.description,
          title: this.propertyTitle,
          images: this.uploadedPhotos,
          evidenceofownership: this.evidenceServerFileName,
          price: this.genericPrice,

          bedrooms: this.bedrooms,
          bathrooms: this.bathrooms,
          status: this.status,
          toilets: this.toilets,
          
        };
        // const xx = Object.fromEntries(
        //   // Object.entries(body).filter(([key, value]) => value !== "")
        //   // return value !== "";
        //   Object.entries(body).filter(([key, value]) => {
        //   console.log(key); // Now 'key' is used, so the error goes away
        //   return value !== "";
        //   })
        // );
        properties.registerPropertyToLandlordStandAlone(JSON.stringify(body), url)
          .then((res) => {
            this.submittingProp = false;
            if (res.success) {
              (this.errorMessage.form = ""), (this.formSubmitted = true);
              // this.clearForm();
              this.$toast.success("SUCCESS, PROPERTY ADDED TO LANDLORD");
              this.forms = [];
              this.$router.push(`/user/landlords`);
              // reset form step
            } else {
              // this.$toast.error("ERROR");
              this.$toast.error(res.error);
              this.submittingProp = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.submittingProp = false;
          });
        // this.loading = false;
        return;
      } {
        this.submittingProp = false;
        return this.$toast.error("ERROR: fill required fileds");
      }
    },
    getPropertyStatuses() {
      this.loadingStatus = true;
      properties.fetchPropertyStatuses().then((data) => {
        this.propertyStatuses = data.propertystatus;
        this.loadingStatus = false;
      });
    },
    addLandlordStandAlone() {
      if (this.isForm1Valid) {
        this.loadingL = true;
        let formData = new FormData();
        formData.append("file", this.landlordIdFile);
        formData.append("address", this.address);
        formData.append("lastname", this.lastName);
        formData.append("firstname", this.firstName);
        formData.append("email", this.email);
        formData.append("phone", this.phone);
        formData.append("dispute_description", this.dispute_description);
        formData.append("property_owner", this.property_owner);
        formData.append("in_court", this.in_court);


        
        // const body = {
        //   address: this.address,
        //   lastname: this.lastName,
        //   firstname: this.firstName,
        //   email: this.email,
        //   phone: this.phone,
        //   file:  formData.append("file", this.landlordIdFile)
        // };
        // JSON.stringify(body)
        properties.registerLandlordStandAlone(formData)
          .then((res) => {
            // console.log("Landlord added", res);
            this.loadingL = false;
            if (res.success) {
              localStorage.setItem("new_land_lordid", res.user.id);
              (this.errorMessage.form = ""), (this.formSubmitted = true);
              // this.clearForm();

              this.$toast.success("SUCCESS, YOU CAN ADD PROPERTY");
              // reset form step
              this.formStep = 2;
            } else {
              this.$toast.error("ERROR");
            }
          })
          .catch((err) => {
            console.log(err);
            this.loadingL = false;
          });
        // this.formStep = 2;
        // this.loadingL = false;
        return;
      }
    },
    validateForm2() {
      // if (this.propertyType == 'Residential' ) {
      //   this.genericPrice = '';
      //   this.bedrooms = '';
      //   this.bathrooms = '';
      //   this.toilets = "";
      //   this.status = "";
      // }
      // if (this.propertyType != 'Residential' ) {
      //   this.propertySubType = '';
      //   this.forms = [
      //     {
      //     unit_name: '',
      //       price: '',
      //       bedrooms: '',
      //       bathrooms: '',
      //       toilets: '',
      //       status: '',
      //     },
      //   ];

      // }
      if (!this.isForm2Valid) {
        return this.$toast.error("Fill all required fileds");
      }
      const lastForm = this.forms[this.forms.length - 1];
      if (this.propertyType == 'Residential' ) {
        if (!this.isForm2Valid || !lastForm.unit_name || !lastForm.price || !lastForm.bedrooms || !lastForm.bathrooms || !lastForm.toilets || !lastForm.status )
         return this.$toast.error("Fill all unit forms");
      }

      if (this.isForm2Valid) {  
        this.formStep = 3;
        return;
      }
    },

    validateFirstName() {
      if (this.firstName.trim() === "") {
        this.errorMessage.firstName = " Please input landlord's first name";
        return false;
      }
      this.errorMessage.firstName = "";
      return true;
    },

    validateLastName() {
      if (this.lastName.trim() === "") {
        this.errorMessage.lastName = " Please input landlord's last name";
        return false;
      }
      this.errorMessage.lastName = "";
      return true;
    },
    validateEmail() {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

      if (this.email.trim() === "") {
        this.errorMessage.email = " Email required!";
        return false;
      }

      if (!regex.test(this.email)) {
        this.errorMessage.email = "Invalid Email";
        return false;
      }

      this.errorMessage.email = "";
      return true;
    },
    validateLandlordAddress() {
      if (this.address.trim() === "") {
        this.errorMessage.address = " Please input landlord's address";
        return false;
      }
      this.errorMessage.address = "";
      return true;
    },
    
    validateTitleInput() {
      if (this.propertyTitle.trim() === "") {
        this.errorMessage.title = " Please input title";
        return false;
      }
      this.errorMessage.title = "";
      return true;
    },
    validatelegalService() {
      if (this.freeLegalService === 'YES') {
        if (this.property_owner === 'NO' || this.in_court === 'NO' || this.dispute_description === '') {
          this.errorMessage.legal = " Please select and fill free legal service fileds";
          return false;
        }
      }
      if (this.freeLegalService === 'NO') {
        this.property_owner === '';
        this.in_court === '';
        this.dispute_description === ''
      }
      this.errorMessage.legal = "";
      return true;
    },
    validatePropertyType() {
      if (this.propertyType === "") {
        this.errorMessage.propertyType = "Please select a property type";
        return false;
      }

      this.errorMessage.propertyType = "";
      return true;
    },
    validatePropertySubType() {
      if (this.propertySubType === "" && this.propertyType != 'Residential') {
        this.errorMessage.propertySubType = "Please select subbtype";
        return false;
      }

      this.errorMessage.propertySubType = "";
      return true;
    },

    validateGenericPriceInput() {
      if (this.genericPrice === "" && this.propertyType != 'Residential') {
        this.errorMessage.genericPrice = "Please select price";
        return false;
      }

      this.errorMessage.propertySubType = "";
      return true;
    },
    validateStatus() {
      if (this.status.trim() === "" && this.propertyType != 'Residential') {
        this.errorMessage.status = " Property status required!";
        return false;
      }
      this.errorMessage.status = "";
      return true;
    },
    validateDescription() {
      if (this.description.trim() === "") {
        this.errorMessage.description = " Property description required!";
        return false;
      }
      this.errorMessage.description = "";
      return true;
    },

    validatePropertyImage() {
      if (this.uploadedPhotos.length == 0) {
        this.errorMessage.propertyImageFile = "Property image(s) required!";
        return false;
      }
      this.errorMessage.propertyImageFile = "";
      return true;
    },

    validatePropertyDoc() {
      if (this.evidenceFile === '') {
        this.errorMessage.evidenceFile = "Property document required!";
        return false;
      }
      this.errorMessage.evidenceFile = "";
      return true;
    },
   
    // validateAddress() {
    //   if (this.address.trim() === "") {
    //     this.errorMessage.address = " address required!";
    //     return false;
    //   }
    //   this.errorMessage.address = "";
    //   return true;
    // },
    validatePhone() {
      if (this.phone.trim() === "") {
        this.errorMessage.phone = " Number required!";
        return false;
      }
      if (
        isNaN(this.phone.trim()) ||
        this.phone.trim().length < 11 ||
        Number(this.phone.trim()) < 0
      ) {
        this.errorMessage.phone = " Invalid number";
        return false;
      }

      this.errorMessage.phone = "";
      return true;
    },
    
    validatePropertyAddress() {
      if (this.propertyAddress.trim() === "") {
        this.errorMessage.propertyAddress = " Please input property address";
        return false;
      }
      this.errorMessage.propertyAddress = "";
      return true;
    },
    validateBusinessType() {
      if (this.typeofbusiness.trim() === "") {
        this.errorMessage.typeofbusiness = " Please select business type";
        return false;
      }
      this.errorMessage.typeofbusiness = "";
      return true;
    },
    validateCity() {
      if (this.city === "") {
        this.errorMessage.city = "Please select city";
        return false;
      }

      this.errorMessage.city = "";
      return true;
    },
    validateLocation() {
      if (this.location === "") {
        this.errorMessage.location = "Please select state";
        return false;
      }

      this.errorMessage.location = "";
      return true;
    },
    checkViewport() {
      this.isMobile = window.innerWidth < 768; // Change 768 to your breakpoint
    },
    getPropertyTypes() {
      this.loadingPropertyType = true;
      //fetch properties
      properties
        .fetchPropertyType()
        .then((data) => {
          //fetch subproperty on first load

          // this.getPropertySubTypes();
          this.propertyTypes = data.propertytypes;
          this.loadingPropertyType = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBusinessTypes() {
      //fetch properties
      properties
        .fetchBusinessTypes()
        .then((data) => {
          // console.log("Business Data", data)
          if (data.success === "success") {
            this.businesstypenumber = data.businesstype;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPropertySubTypes(id) {
      this.loadingPropertySubType = true;
      properties
        .fetchPropertySubType(id)
        .then((data) => {
          this.propertySubTypes = data.propertysubtypes;
          this.loadingPropertySubType = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStates() {
      this.loadingLocation = true;
      properties
        .fetchStates()
        .then((data) => {
          this.loadingLocation = false;
          // this.getCities();
          this.states = data.states;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCities(id) {
      this.loadingCity = true;
      properties
        .fetchCities(id)
        .then((data) => {
          this.loadingCity = false;

          this.cities = data.cities;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPropCategory() {
      properties
        .fetchPropCategory()
        .then((data) => {
          // console.log("Category", data);
          if (data.success) {
            // this.propertyCategory = data.categories;
            this.propertyCategory = data.updated_category;
          }
          // this.loadingLocation = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    sortedStates() {
      return [...this.states].sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
      });
    },
    sortedCity() {
      return [...this.cities].sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
      });
    },
    propertyTypeId() {
      return (
        this.propertyTypes.find((el) => el.type === this.propertyType).id || ""
      );
    },
    propertySubTypeId() {
      return (
        this.propertySubTypes.find((el) => el.subtype === this.propertySubType)
          .id || ""
      );
    },
    stateId() {
      return this.states.find((el) => el.name === this.location).id || "";
    },
    cityId() {
      return this.cities.find((el) => el.name === this.city).id || "";
    },
    

    isForm1Valid() {
      return (
        this.validateFirstName() &&
        this.validateLastName() &&
        this.validatePhone() &&
        this.validateEmail() &&
        this.validateLandlordAddress()  &&
        this.validatelegalService()
      );
    },
    isForm2Valid() {
      return (
        this.validateLocation() && 
        this.validateCity() && 
        this.validateDescription() &&

        this.validateTitleInput() &&
        this.validatePropertyType() &&
        this.validatePropertySubType() &&
        this.validateBusinessType() &&
        // this.validateState() &&
        // this.validatePropertyValue() &&
        this.validateGenericPriceInput() &&
        this.validateStatus()
      );
    },
    isForm3Valid() {
      return (
        this.validatePropertyDoc() &&
        this.validatePropertyImage()
      )
    },
    isForm4Valid() {
      return this.validateBedrooms();
    },
    isFormValid() {
      return (
        this.validateTitleInput() &&
        this.validatePropertyType() &&
        this.validatePropertySubType() &&
        this.validateBusinessType() &&
        this.validateState() &&
        this.validatePropertyValue() &&
        this.validateGenericPriceInput() &&
        this.validateStatus() &&
        this.landlordFirstname !== "" &&
        this.landlordLastname !== "" &&
        this.landlordGender !== "" &&
        !isNaN(this.landlordPhone)
      ); 
     
    },
    theRentValueAmount: {
      get() {
        return this.genericPrice;
      },
      set(value) {
        let amountt = value.split(",").join("");
        // this.amount2 = (amountt);
        let decimalArray = amountt.split(".");
        if (decimalArray.length === 2) {
          amountt = decimalArray[0];
          amountt = (+amountt).toLocaleString();
          this.genericPrice = amountt + "." + decimalArray[1];
        } else {
          this.genericPrice = (+amountt).toLocaleString();
          // const xx = this.genericPrice.replace(/[^0-9.]/g, "");
          // console.log("xx", xx);
        }
      },
    },
   
  },
  watch: {
    duplicateFormNumber(newData, oldData) {
      newData = String(newData); 
      if (newData.startsWith("0")) {
        return this.$toast.error("Number of unit can't be 0");
      }
      if (newData) {
       this.duplicateFormData(newData, oldData);
      }
    },
    propertyType(newType) {
      if (newType) {
      this.subtypeId = this.propertyTypes.find((el) => el.type === newType)[
        "id"
      ];
      }

      if (this.subtypeId) {
        this.getPropertySubTypes(this.subtypeId);
      } else {
        this.propertySubTypes = [];
      }
      if(newType && this.propertyType != 'Residential') {
        this.forms = [
          {
            unit_name: '',
            price: '',
            bedrooms: '',
            bathrooms: '',
            toilets: '',
            status: '',
          },
        ];
      }
      else {
        this.genericPrice = '';
        this.bedrooms = '';
        this.bathrooms = '';
        this.toilets = "";
      }
      // if (newType) {
      //    this.subtypeId = this.propertyTypes.find((el) => el.type === newType)[
      //     "id"
      //   ];

      //   if (this.subtypeId) {
      //     this.getPropertySubTypes(this.subtypeId);
      //   } else {
      //     this.propertySubTypes = [];
      //   }
      // }
    },
    location(newState) {
       if (newState) {
        this.stateIdd = this.states.find((el) => el.name === newState)["id"];
       }

        if (this.stateIdd) {
          this.getCities(this.stateIdd);
        } else {
          this.cities = [];
        }
    },
    

  },
};
</script>


<style lang="scss" scoped>


.unit-count-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #2B3352;
  }
}
.unit-text-button {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  h6 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #2B3352;
  }
  button {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #0033ea;
    border-bottom: 1px solid #0033ea;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.addNewUnit {
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #555C74;
    margin-top: 10px;
  }
}

.dynamic-input {
  padding: 1rem 10px !important;
  border: 1px solid #d2d3d3;
  border-radius: 6px;
  color: #57595a;
  font-size: 1rem;
  font-family: Lato;
  height: inherit;
  outline: 0;
  box-shadow: none;
  // height: 48px;
}
.prop-reviw-holder {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20;
  img {
    cursor: pointer;
  }
}
.general-info-content {
  div {
    margin-bottom: 10px;
    h5 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #2B3352;
      overflow-wrap: break-word;
    }
    h6 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #555C74;
      overflow-wrap: break-word;
    }
    h6:last-child {
      margin-bottom: 0;
    }
  }
}

.info-holder {
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  padding: 20px 15px;
}
.review-top-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  h5 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #2B3352;

  }
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #0033EA;
  }
}
.no-prop-add {
  margin-top: 30px;
  border: 1px dashed #E4E7EC;
  min-height: 128px;
  background: #FDFDFD;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  h2 {
    color: #555C74;
    font-size: 50px;
    font-family: Lato;
  }
  h6 {
    color: #555C74;
    font-size: 16px;
    font-family: Lato;
    font-weight: 400;
  }
}

.upload-btn-id {
  p {
    color: #57595a;
    font-size: 14px;
    line-height: 1.8;
  }
  button {
    display: block;
    width: 100%;
    padding: 1rem 10px;
    border: 1px solid #d2d3d3;
    border-radius: 6px;
    color: #57595a;
    font-size: 1rem;
    font-family: Lato;
    height: 62px;
    margin-bottom: 0.5rem;
    outline: 0;
    box-shadow: none;
  }
}

.submit-btn:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.btn-container {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}
.container2 {
  background-color: white;
  border-radius: 10px;

  padding: 2rem;
}
.form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  gap: 1.5rem;
  font-family: "Lato", sans-serif;
  padding: 5px 0;
}
.input-group {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.input-group > * {
  flex: 1;
}

@media screen and (min-width: 768px) {
  .form {
    grid-template-columns: 1fr;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }
}

.form > * {
  align-self: center;
}

label {
  display: block;
}


// Upload sesseions starts
.upload-holder {
  border: 1px solid #E4E7EC;
  padding: 15px;
  border-radius: 6px;
  // margin-bottom: 10px;
}
.holding-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  button {
    border: 1px solid #555C74;
    color: #555C74;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    outline: 0;
    box-shadow: none;
  }
  p {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #2B3352;
    border: 1px solid #E4E7EC;
    border-radius: 4px;
    padding: 5px;
    display: inline-block;
    margin: 5px !important;
    margin-left: 0 !important;
    position: relative;
    span {
      padding: 4px 8px;
      border: 1px solid;
      border-radius: 50px;
      position: absolute;
      bottom: 72%;
      left: 93%;
    }
  }
  h4 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #2B3352;
  }
  h6 {
    margin-top: 10px;
    margin-bottom: 0px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #555C74;
  }
}
.uploading-doc {
  color: #48b881 !important;
  text-align: center;
  margin-top: 20%;
  font-size: 14px !important;
  animation: blink 1s infinite; 
}
// Upload session ends



@media screen and (min-width: 1024px) {
  .steps-container {
    display: block;
  }
}

.forms-container {
  flex: 1;
}

#progressbar {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  // color: white;
  color: #555C74;
  text-transform: capitalize;
  // font-size: 9px;
  // width: 33.33%;
  width: 24.33%;
  float: left;
  position: relative;

  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: center;

  // display: flex;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 20px;
  line-height: 20px;
  display: block;
  font-size: 10px;
  color: #333;
  background: white;
  border-radius: 50px;
  margin: 0 auto 5px auto;
  position: relative;
  z-index: 3;
}


/*progressbar connectors*/

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #A1AEBE;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: 2;
  /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: #0033ea;
  color: white;
}
// Progress indicator ends


@media screen and (max-width: 599px) { 
  .container2 {
    // padding: 0;
  }
  .finish-holder {
    margin: 0;
  }
  #progressbar {
    padding-top: 30px;
  }
  #progressbar li {
    font-size: 9px;
   }
}

.input-error {
  color: rgb(234, 0, 0);
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  position: absolute;
}

.form-control {
  box-shadow: none;
  outline: 0;
}
</style>